@import '~@stitch-fix/mode-style-system';

.container {
  max-height: 78vh;
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  grid-template-areas: 'stack';
  overflow: hidden;
  --media-grid-area: stack;
  --content-grid-area: stack;
  --content-background-color: transparent;

  &.mediaFirst {
    @include below-breakpoint-sm {
      max-height: unset;
      grid-template-areas:
        'media'
        'content';
      --media-grid-area: media;
      --content-grid-area: content;
    }
  }

  &.contentFirst {
    @include below-breakpoint-sm {
      max-height: unset;
      grid-template-areas:
        'content'
        'media';
      --media-grid-area: media;
      --content-grid-area: content;
      --content-background-color: rgba(255, 255, 255, 0.9);
    }
  }
}

.content {
  grid-area: var(--content-grid-area);
  place-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  background-color: var(--content-background-color);

  @include at-breakpoint-md {
    max-width: 54rem;
    margin: 0 2rem;
  }

  @include below-breakpoint-sm {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.textShadow {
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
}

.whiteContentBackground {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  @include at-breakpoint-md {
    padding: 2rem;
  }
}

.media {
  grid-area: var(--media-grid-area);
  max-height: 78vh;
}
